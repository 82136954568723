"use client";

import React, {useState, useEffect} from "react";
import {useSession} from "next-auth/react";
import Link from "next/link";
import Image from "next/image";
import {getInitials} from "@/lib/utils";

export default function ProfileBtn() {
  const {data: session, status} = useSession();

  // Standard user info states
  const [userName, setUserName] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    if (status === "authenticated" && session?.user) {
      // Use NextAuth user if available
      if (session.user.name) {
        setUserName(session.user.name);
      }
      if (session.user.image) {
        setAvatarUrl(session.user.image);
      }
    }
  }, [status, session, userName, avatarUrl]);

  // Don’t render anything while NextAuth status is loading,
  // or if user is not logged in
  if (status === "loading") return null;
  if (!session?.user) return null;

  const initials = getInitials(userName);

  return (
    <Link
      href="/profile"
      className="
        group
        flex items-center space-x-2 cursor-pointer
        transition-colors text-white hover:text-primary
        hover:bg-transparent uppercase duration-200
        px-3 py-2 text-2xl
      ">
      {/* Avatar or fallback */}
      {avatarUrl ? (
        <Image
          src={avatarUrl}
          width={24}
          height={24}
          alt="Profile Avatar"
          className="
            w-6 h-6 rounded-full object-cover
            transition-opacity duration-200
            group-hover:opacity-80
          "
        />
      ) : (
        <div
          className="
            w-6 h-6 rounded-full bg-primary text-black
            flex items-center justify-center uppercase font-bold
            transition-opacity duration-200 text-lg
            group-hover:opacity-80
          ">
          {initials}
        </div>
      )}

      {/* Display user name */}
      <span className="inline-block max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis">{userName || "Unnamed User"}</span>
    </Link>
  );
}
