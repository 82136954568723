"use client";

import {useState, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import NavLink from "./NavLink";
import NavLinkLogo from "./NavLinkLogo";
import {usePathname} from "next/navigation";
import ProfileBtn from "./ProfileBtn";
import {useSession} from "next-auth/react";
import Link from "next/link";

const Navbar = () => {
  const {data: session, status} = useSession();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const pathname = usePathname();

  // Lock the body scroll when menu is open and prevent horizontal scroll
  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isMenuOpen]);

  const handleLinkClick = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <header className={`absolute z-50 top-0 left-0 w-full transition-all duration-300 overflow-x-hidden ${isMenuOpen ? "bg-black bg-opacity-90" : "bg-transparent"}`}>
      <div className="container mx-auto">
        <nav className="flex items-center justify-between py-4">
          {/* Logo */}
          <NavLinkLogo />

          {/* Mobile Menu Toggle */}
          <button title="Navigation menu" className="lg:hidden text-white focus:outline-none z-50" aria-controls="navbarNavDropdown" aria-expanded={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className="h-6 w-6" />
          </button>

          {/* Fullscreen Overlay Menu */}
          <div
            className={`fixed inset-0 bg-black bg-opacity-80 transition-transform duration-300 ease-in-out lg:static lg:bg-transparent lg:flex lg:items-center lg:space-x-6 ${
              isMenuOpen ? "translate-x-0" : "translate-x-full lg:translate-x-0"
            }`}
            id="navbarNavDropdown">
            <ul className={`flex flex-col lg:flex-row items-center lg:space-x-2 space-y-4 lg:space-y-0 text-2xl font-tungsten-semibold mt-16 lg:mt-0 ${isMenuOpen ? "pb-10" : ""} lg:pb-0`}>
              <li>
                <NavLink href="https://exchange.mozgii.com/" label="Exchange" onClick={handleLinkClick} />
              </li>
              <li>
                <NavLink href="https://unity.mozgii.com/" label="Unity" onClick={handleLinkClick} />
              </li>
              <li>
                <NavLink href="https://studio.mozgii.com/" label="Studio" onClick={handleLinkClick} />
              </li>
              <li>
                <NavLink href="https://music.mozgii.com/" label="Music" onClick={handleLinkClick} />
              </li>
              <li>
                <NavLink href="/mozg-unit-economy" label="Unit Economy" isActive={pathname === "/mozg-unit-economy"} onClick={handleLinkClick} />
              </li>

              <li>
                <NavLink href="/news-and-blog" label="News & Blog" isActive={pathname === "/news-and-blog"} onClick={handleLinkClick} />
              </li>
              <li>
                <NavLink href="/pitch-deck" label="Investors & Pitch Deck" isActive={pathname === "/pitch-deck"} onClick={handleLinkClick} />
              </li>
              {/* <li>
                <svg className="w-14 h-12 animate-pulse text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 250 70">
                  <rect x="20" y="7" width="250" height="70" rx="0" />
                </svg>
              </li> */}
              <li>
                {status === "loading" ? (
                  // Show a loading spinner or SVG
                  <svg className="w-14 h-12 animate-pulse text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 320 110">
                    <rect x="20" y="7" width="320" height="110" rx="0" />
                  </svg>
                ) : !session?.user ? (
                  <Link href="/login" onClick={handleLinkClick} className=" text-black bg-gold hover:bg-black hover:text-gold uppercase transition-all duration-300 px-2 text-2xl">
                    Log In
                  </Link>
                ) : (
                  <ProfileBtn />
                )}
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
