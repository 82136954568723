"use client";
import {useEffect} from "react";
import {onAuthStateChanged} from "firebase/auth";
import {signIn, signOut} from "next-auth/react";
import {auth} from "@/lib/firebaseConfig";

export default function useFirebaseOnAuthStateChanged() {
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // User is signed in: get the latest token
          const token = await user.getIdToken(true);
          // Update NextAuth session with the fresh token using your credentials provider.
          await signIn("firebase-credentials", {
            idToken: token,
            redirect: false, // avoid full page reload
            callbackUrl: window.location.href // adjust callback URL as needed
          });
        } catch (error) {
          console.error("Error updating NextAuth session with Firebase token:", error);
        }
      } else {
        try {
          // User is signed out in Firebase: clear the NextAuth session.
          await signOut({
            redirect: false,
            callbackUrl: window.location.href // adjust as needed
          });
        } catch (error) {
          console.error("Error during NextAuth sign out:", error);
        }
      }
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, []);
}
